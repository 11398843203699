import { useEffect, useState } from 'react'
import './Product.css'
import { ProductCanvas } from './productCanvas'

import { useRoute, useLocation } from 'wouter'
import { Suspense } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { useRef } from 'react'
import { OrbitControls } from '@react-three/drei'
import { Blackhole } from '../../Component/blackhole/Blackhole'
import { navigate } from 'wouter/use-location'
import { useNavigate } from 'react-router-dom'


function BkHole(props){
    const mesh = useRef();
    useFrame((state, delta) => {
        mesh.current.rotation.y += 0.0001
      });
      return(
        <>
            <OrbitControls autoRotate={true} enableZoom={false}/>
            <mesh {...props} ref={mesh}>
            <sphereGeometry args={[1,28,28]} />
            <meshPhysicalMaterial
            color="grey" />
            <Blackhole scale={3}/>
        </mesh>
        </>
      )
}

export default function Product(){

    const [productData , setProductData] = useState({})
    async function fetchData(){
        const id = 'productDataMoonD'
        const current = new Date()
        const productDa = localStorage.getItem(id)
        if(productDa === null){
            await fetch('https://moon-web-88cb6.firebaseio.com/Europa.json')
            .then(async(res)=>await res.json())
            .then((data)=>{
                setProductData(data)
                console.log(productData)
                // set item in localstorage

                localStorage.setItem(
                    id,
                    JSON.stringify(data)
                )
                // set date in localstorage
                localStorage.setItem(
                    'MoonDate',
                    `${current.getDate()}`
                )
            })
            .catch(e=>console.log(e))

            // save data to memory 
            // change state
        }
        else{
            // get data from memory
            console.log(JSON.parse(productDa))
            setProductData(JSON.parse(productDa))
            if((current.getDate+1)  > (parseInt(localStorage.getItem("MoonDate")))+5){
                setProductData(JSON.parse(productData))
            }
                else{
                    await fetch('https://moon-web-88cb6.firebaseio.com/Europa.json')
                .then(res=>res.json())
                .then((data)=>{
                    setProductData(data)
                    // set item in localstorage
                    localStorage.setItem(
                        id,
                        JSON.stringify(data)
                    )
                    // set date in localstorage
                    localStorage.setItem(
                        'MoonDate',
                        `${current.getDate()}`
                    )
                })
                .catch(e=>console.log(e))
                }
        }
       }

    useEffect(()=>{
        // check if data is availabe in the cache or pc memory .
            // Is data 5 days old?
            // Yes -> Load from database
            // no -> load form memory
            fetchData()
           
    },[])

    const [imgInd,setimgInd] = useState(0)
    const [pInd , setPInd] = useState(0)
    const [ven,setVend] = useState(false)
    const [pinCode,setPinCode] = useState(null)
    const [, params] = useRoute('/products/:id')
    const [, setLocation] = useLocation()

    const navigate = useNavigate()



    

   return(
    <div className='product' style={{backgroundColor:'black',width:'100vw',height:'100vh'}}>
        <h1 style={{color:'white'}}>Products not available right now</h1>
        <h3 onClick={()=>navigate('/')}>GET BACK</h3>
    </div>
   )
}