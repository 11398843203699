import React from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import Product from '../../Pages/Product/Product'
import Footer from '../Footer/Footer'
import { AnimatePresence } from 'framer-motion'
import SelectionPage from '../../Pages/Selection/SelectionPage'


function AnimatedRoute() {
    const location =useLocation()
  return (
   <AnimatePresence>
        <Routes location={location} key={location.pathname}>
          <Route path='/' element={<SelectionPage/>}/>
          <Route path="/products" element={<Product/>} />
          <Route path="/contact" element={<Footer />} />
        </Routes>   
   </AnimatePresence>
  )
}

export default AnimatedRoute