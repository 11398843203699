import { Canvas, useFrame, useLoader } from "@react-three/fiber";
import {Physics , RigidBody, BallCollider, CylinderCollider} from '@react-three/rapier'
import { useEffect, useRef } from "react";
import * as THREE from "three"
import {EffectComposer , N8AO , SSAO} from '@react-three/postprocessing'
import img from '../../asset/other/Moon.jpg'
import { Suspense } from "react";
import instagram from '../../asset/instagram.png'
import linkedin from '../../asset/linkedin.png'
import youtube from '../../asset/youtube.png'
import map from '../../asset/map.png'
import { useState } from "react";
import './Footer.css'

THREE.ColorManagement.legacyMode = false
const sphereGeometry = new THREE.SphereGeometry(1, 28, 28)
const baubles = [...Array(30)].map(() => ({ scale: [0.75, 0.75, 1, 1, 1.25][Math.floor(Math.random() * 5)] }))



function Bauble({ vec = new THREE.Vector3(), scale, r = THREE.MathUtils.randFloatSpread }) {
    // const { nodes } = useGLTF("/cap.glb")
    const api = useRef()
    const base = useLoader(THREE.TextureLoader,img)
    useFrame((state, delta) => {
      delta = Math.min(0.1, delta)
      try {
        api.current.applyImpulse(
          vec
            .copy(api.current.translation())
            .normalize()
            .multiply({ x: -50 * delta * scale, y: -150 * delta * scale, z: -50 * delta * scale }),
        )
      } catch (error) {
        
      }
    })
    return (
      <RigidBody linearDamping={0.75} angularDamping={0.15} friction={0.2} position={[r(20), r(20) - 25, r(20) - 10]} ref={api} colliders={false} dispose={null}>
        <BallCollider args={[scale]} />
        <CylinderCollider rotation={[Math.PI / 2, 0, 0]} position={[0, 0, 1.2 * scale]} args={[0.15 * scale, 0.275 * scale]} />
        <mesh castShadow receiveShadow scale={scale} geometry={sphereGeometry} >
          <meshBasicMaterial map={base}/>
          </mesh>
      </RigidBody>
    )
  }
  
  function Pointer({ vec = new THREE.Vector3() }) {
    const ref = useRef()
    const handleMouseMove = (event) => {
      // Calculate the mouse position relative to the window dimensions
      const x = (event.clientX / window.innerWidth) * 2 - 1
      const y = -(event.clientY / window.innerHeight) * 2 + 1
  
      // Map the mouse position to your desired range (adjust as needed)
      const newX = x * 10
      const newY = y * 10
  
      // Update the vector
      vec.set(newX, newY, 0)
  
      // Set the next kinematic translation
      ref.current?.setNextKinematicTranslation(vec)
    }
  
    // Add event listener to track mouse movement
    useEffect(() => {
      document.addEventListener('mousemove', handleMouseMove)
      return () => {
        document.removeEventListener('mousemove', handleMouseMove)
      }
    }, [])

    return (
      <RigidBody position={[100, 100, 100]} type="kinematicPosition" colliders={false} ref={ref}>
        <BallCollider args={[2]} />
      </RigidBody>
    )
  }

export default function Footer(){

  const [showMap , setShowMap ] = useState(false)
  const [showPages , setShowPages] = useState(false)

    return(
        <>
          <div style={{height:'100vh',width:'100vw',position:'relative',backgroundColor:'#F8F0EE'}}>
          <Suspense>
            <Canvas
            shadows
            gl={{ alpha: true, stencil: false, depth: false, antialias: false }}
            camera={{ position: [0, 0, 20], fov: 32.5, near: 1, far: 100 }}
            onCreated={(state) => (state.gl.toneMappingExposure = 1.5)}>

            <ambientLight intensity={1} />
            <spotLight position={[20, 20, 25]} penumbra={1} angle={0.2} color="white" castShadow shadow-mapSize={[512, 512]} />
            <directionalLight position={[0, 5, -4]} intensity={4} />
            <directionalLight position={[0, -15, -0]} intensity={4} color="red" />

            <Physics>
                <Pointer />
                {baubles.map((props, i) => <Bauble key={i} {...props} />) /* prettier-ignore */}
            </Physics>
            <EffectComposer disableNormalPass multisampling={0}>
            <N8AO color="red" aoRadius={1} intensity={1} />
            <SSAO />
        </EffectComposer>
            </Canvas>
          </Suspense>
          </div>
          <div style={{position:'absolute',top:'0',left:'0',zIndex:'5'}}>
              <a style={{top:'5vh',left:'5vw',color:'black',position:'absolute',fontSize:'12vh',borderBottom:'2px solid black',paddingBottom:'10px'}} href="http://www.moondna.co.in" >Moon DnA</a>
          </div>
          <div style={{
            width:'12vw',
            // width:'10vw',
            display:'flex',
            justifyContent:'space-between',
            alignItems:'center',
            position:'absolute',bottom:'5vh',left:'5vw',zIndex:'5',borderBottom:'2px solid black',paddingBottom:'10px'  }}>
            <p style={{fontSize:'2vh'}}>Follow us</p>
            <a className="link-logo" href="http://www.instagram.com">
              <img src={instagram} style={{width:'30px'}} alt="" />
            </a>
            <a className="link-logo" href="http://www.youtube.com">
              <img src={youtube} style={{width:'30px'}} alt="" />
            </a>
            <a className="link-logo" href="http://linkedin.com">
              <img src={linkedin} style={{width:'30px'}} alt="" />
            </a>
          </div>
          <div style={{position:'absolute',width:'10vw',top:'5vh',right:'5vw',zIndex:'5',display:'flex',justifyContent:'space-around'
          ,alignItems:'center',transition:'all 1s ease',transitionDelay:'1s',opacity:showMap?'0':'1',borderBottom:'2px solid black',paddingBottom:'10px'}}>
            <p style={{fontSize:'2vh',color:'black'}} onMouseEnter={()=>{setShowMap(true)}}>Click For Direction</p>
            {/* <a href="#"> */}
              <img src={map} style={{width:'30px'}} alt="" />
            {/* </a> */}
          </div>
          <div style={{position:'absolute',top:'5vh',right:'5vw',zIndex:'6',opacity:'0.8'}}>
            <iframe title="moonmap" style={{width:showMap?'20vw':'0vw',aspectRatio:'2/1',borderRadius:'20px',transition:'all 1s ease',transitionDelay:'1s'}}
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3662.523702242249!2d85.31908677532425!3d23.369264428932283!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f4e188911e217b%3A0xb25e240dc69c7f77!2sMoon%20design%20and%20automation%20%7C%20Moon%20Studio!5e0!3m2!1sen!2sin!4v1695900352716!5m2!1sen!2sin"   allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            <p style={{opacity:showMap?'1':'0',transition:'all 1s ease',transitionDelay:'1s'}} onMouseLeave={()=>{setShowMap(false)}}
            
            > - BACK</p>
          </div>

          <div style={{position:'absolute',bottom:'5vh',right:'5vw',zIndex:'6',borderRight:'1px solid black',paddingRight:'10px'}}
          onMouseEnter={()=>{setShowPages(true)}}
          onMouseLeave={()=>{setShowPages(false)}}
          onClick={()=>{setShowPages(!showPages)}}
          >
            <p>Our Pages</p>
            <div style={{height:showPages?'30vh':0,overflow:'hidden',transition:"all 1s ease",display:'flex',flexDirection:'column',justifyContent:'space-around'}}>
              <a style={{color:'grey'}} href="https://www.itivarma.com">Ar Iti Varma - Moon Design</a>
              <a style={{color:'grey'}} href="https://www.moondna.co.in/Studies">Moon Information</a>
            </div>
          </div>

          <div className="mail-us" style={{position:'absolute',top:'50%',width:'30vw',border:'2px solid black',zIndex:'6'}}
          >
            <p>Mail us at : moon@moondna.co.in</p>
          </div>

        </>
    )
}
