import React, { useState, useEffect } from 'react';
import './App.css'
import {BrowserRouter} from 'react-router-dom'
import AnimatedRoute from './Component/Route/AnimatedRoute.js';



const CursorTracker = () => {
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (event) => {
      const { clientX, clientY } = event;
      setTimeout(() => {
        setCursorPosition({ x: clientX, y: clientY });
      }, 50);
    };

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div
    className='zoop-cur'
    id='zoop'
    style={{
      position: 'fixed',
      top: cursorPosition.y,
      left: cursorPosition.x,
      transform: 'translate(-50%, -50%)',
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      border: '2px solid white',
      backgroundColor: 'transparent',
      mixBlendMode:'difference',
      pointerEvents: 'none', // Ignore pointer events
      zIndex:'5'
    }}
    ></div>
  );
};

const App = () => {
  return (
    <div id='app-main' >
      <BrowserRouter>
        <AnimatedRoute/>
        {(window.innerWidth < 1000)?<></>:<CursorTracker/>}
      </BrowserRouter>
    </div>
  );
};

export default App;
