import * as THREE from 'three'
import { useEffect, useRef, useState } from 'react'
import { Canvas, extend, useFrame, useThree } from '@react-three/fiber'
import { useCursor, MeshPortalMaterial, CameraControls, Text,  useGLTF } from '@react-three/drei'
import { useRoute, useLocation } from 'wouter'
import { easing, geometry } from 'maath'

import './Product.css'
import { useMemo } from 'react'


function BufferPoints({ count = 500 }) {

  const [mousePos,setMousePos] = useState({})
  useEffect(()=>{
      const handleMouseMove = (event) => {
          setMousePos({ x: event.clientX, y: event.clientY });
        };
    
        window.addEventListener('mousemove', handleMouseMove);
    
        return () => {
          window.removeEventListener(
            'mousemove',
            handleMouseMove
          );
        };
  },[])
  const mesh = useRef();
  const points = useMemo(() => {
    const p = new Array(count).fill(0).map((v) => (0.5 - Math.random()) * 15);
    return new THREE.BufferAttribute(new Float32Array(p), 3);
  }, [count]);
  // useFrame((state, delta) => {
  //     if(mousePos.x<window.innerWidth/2 && mousePos.y<window.innerHeight/2){
  //         //4th quad
  //         mesh.current.rotation.x  -= 0.001
  //         mesh.current.rotation.y -=0.001 
  //     }
  //     else if(mousePos.x<window.innerWidth/2 && mousePos.y>window.innerHeight/2){
  //         //3rd quad
  //         mesh.current.rotation.x  += 0.001
  //         mesh.current.rotation.y -=0.001
  //     }
  //     else if(mousePos.x>window.innerWidth/2 && mousePos.y>window.innerHeight/2){
  //         mesh.current.rotation.x  += 0.001
  //         mesh.current.rotation.y +=0.001
  //     }
  //     else if(mousePos.x>window.innerWidth/2 && mousePos.y<window.innerHeight/2){
  //         mesh.current.rotation.x  -= 0.001
  //         mesh.current.rotation.y +=0.001
  //     }
  //     else{
  //         mesh.current.rotation.y -=0.0001
  //     }
  //   });
  return (
    <mesh ref={mesh}>
      <points >
          <bufferGeometry>
          <bufferAttribute attach={"attributes-position"} {...points} />
          </bufferGeometry>
          <pointsMaterial
          transparent
          size={0.025}    
          threshold={0.01}
          color={'##F8F0EE'}
          sizeAttenuation={true}
          depthWrite={false}
          />
    </points>
    </mesh>
  );
}


export const ProductCanvas = ()=> {


  return (
    <div style={{width:'100%',height:'100%'}}></div>
  )
}


