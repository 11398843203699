
import './HeroMain.css'
import HeaderDark from '../Header/HeaderDark';


export default function HeroMain(){

    
    

    return( 
        <>
             
            <div className='hero-main'>
                {/* <HeaderDark/> */}
                <div className="hero-cen">
                    <div className='hero-cen-val'>
                            <section>
                                <a href="/products" class="testing" data-text="View Products">View Products</a>
                            </section>
                            <section>
                                <a href="/contact" class="testing" data-text="View Projects">Contact us</a>
                            </section>
                        </div>
                </div>
                <div className='hero-text'>
                    <p>Lifestyle Elevated, Tasks Automated. </p>
                    <p className='blink'>|</p>
                </div>
            </div>
        </>
    )
}