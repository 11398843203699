import { useEffect, useMemo, useRef, useState } from 'react';
import { Canvas, useFrame, useLoader } from '@react-three/fiber';
import { BufferAttribute, RepeatWrapping, TextureLoader } from 'three';
import './SelectionPage.css'
import img from '../../asset/other/Moon.jpg'
import { OrbitControls } from '@react-three/drei';
import HeroMain from '../../Component/Hero/HeroMain';
import {motion} from 'framer-motion'

function Planet(props) {
  const mesh = useRef();
  // rotate the box
  const base = useLoader(TextureLoader,img)
  base.wrapS = RepeatWrapping;
  base.wrapT = RepeatWrapping;
  base.repeat.set(1, 1);
  useFrame((state, delta) => {
    mesh.current.rotation.y += 0.001
  });
  // draw the box
  return (
      <>
      <OrbitControls autoRotate={true} enableZoom={false}/>
      <mesh {...props} ref={mesh}>
        <sphereGeometry args={[1,28,28]} />
        <meshPhysicalMaterial
        map={base} 
        color="grey" />
      </mesh>
      </>
  );
}

function BufferPoints({ count = 5000 }) {

    const [mousePos,setMousePos] = useState({})
    useEffect(()=>{
        const handleMouseMove = (event) => {
            setMousePos({ x: event.clientX, y: event.clientY });
          };
      
          window.addEventListener('mousemove', handleMouseMove);
      
          return () => {
            window.removeEventListener(
              'mousemove',
              handleMouseMove
            );
          };
    },[])
    const mesh = useRef();
    const points = useMemo(() => {
      const p = new Array(count).fill(0).map((v) => (0.5 - Math.random()) * 15);
      return new BufferAttribute(new Float32Array(p), 3);
    }, [count]);
    useFrame((state, delta) => {
        if(mousePos.x<window.innerWidth/2 && mousePos.y<window.innerHeight/2){
            //4th quad
            mesh.current.rotation.x  -= 0.001
            mesh.current.rotation.y -=0.001 
        }
        else if(mousePos.x<window.innerWidth/2 && mousePos.y>window.innerHeight/2){
            //3rd quad
            mesh.current.rotation.x  += 0.001
            mesh.current.rotation.y -=0.001
        }
        else if(mousePos.x>window.innerWidth/2 && mousePos.y>window.innerHeight/2){
            mesh.current.rotation.x  += 0.001
            mesh.current.rotation.y +=0.001
        }
        else if(mousePos.x>window.innerWidth/2 && mousePos.y<window.innerHeight/2){
            mesh.current.rotation.x  -= 0.001
            mesh.current.rotation.y +=0.001
        }
        else{
            mesh.current.rotation.y -=0.0001
        }
      });
    return (
      <mesh ref={mesh}>
        <points >
            <bufferGeometry>
            <bufferAttribute attach={"attributes-position"} {...points} />
            </bufferGeometry>
            <pointsMaterial
            transparent
            size={0.025}    
            threshold={0.01}
            color={'##F8F0EE'}
            sizeAttenuation={true}
            depthWrite={false}
            />
      </points>
      </mesh>
    );
  }

export default function SelectionPage() {   

  // set -3 for full rotate
  const [rotate,setRotate] = useState(0)
  return (
    <motion.div
    
    animate={{width:'100vw'}}
    exit={{width:'0vw'}}
    initial={{width:'0vw'}}
    >
        <div className='head-style' onClick={()=>setRotate((rotate === 0)?-3:0)}>
            <div className='head-s p-head mfont' data-p-head='Moon DnA'>
                  <p>Moon DnA</p>
                </div>
        </div>
        <div className='started'> 
          <HeroMain/>
        </div>
        <Canvas
          camera={{
            position:[2,3,10]
          }}
        style={{height:'100vh',width:'100vw',position:'absolute', zIndex:'-2',backgroundColor:'black'}}
        dpr={window.devicePixelRatio}>
        <perspectiveCamera 
        // ref={mainMesh}
          position={[0,0,rotate]}
        >
            <ambientLight />
            {/* <OrbitControls/> */}
        <pointLight intensity={2} color={'#F8F0EE'} position={[10, 10, -10]} />
        <Planet className="planet" position={[0, 0, 0]} scale={2.5} />
        <BufferPoints/>
        </perspectiveCamera>
    </Canvas>
    </motion.div>  
  );
}